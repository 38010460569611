import naturalSort from '../externalDependencies/naturalSort';

// Note: this modifies the function globally, not locally.
naturalSort.insensitive = true;

export default naturalSort;

export const naturalSortBy = field => (a, b) => naturalSort(a[field], b[field]);

export const naturalSortByName = naturalSortBy('name');
