import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import InlineEditor from '../components/InlineEditor';
import { Colors } from '../styles';
import { Icon, IconTypes } from '../components/icons';

const titleStyles = css`
  font-weight: bold;
`;

export const Title = styled.div`
  ${titleStyles};
`;

export const InlineEditorTitle = styled(InlineEditor)`
  ${titleStyles};
`;

export const NoChangesMessage = styled.div`
  color: ${Colors.gray5};
  font-style: italic;
  font-size: 0.875rem;
`;

export const InlineChangeArrow = () => (
  <Icon type={IconTypes.ARROW} direction="right" />
);
