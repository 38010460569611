import { getSentimentStatus, SENTIMENT_STATUS } from '../utils/sentimentStatus';
import { getLanguageName } from '../utils/projectUtils';
import { Version } from '../utils/version';

/**
 * @typedef {Object} BuildChanges
 * @property {boolean} hasBuildChanges - indicates if there will be any
 *                                       difference upon rebuilding the project
 * @property {boolean} canRebuild
 * @property {boolean} upgradingScience
 * @property {boolean} significantScienceChange
 * @property {boolean} addingSentiment
 * @property {boolean} hasUnbuiltAssertions
 * @property {boolean} changingLanguage
 * @property {string} currentLanguageName - Name of current project language
 * @property {string} nextLanguageName - Name of next build language
 */

/**
 * Utility for checking if a project will change when rebuilt. Bundles
 * information about what specifically will change.
 *
 * @param {Object} project
 * @param {Object} serverStatus - from the AuthContext
 * @param {ConceptManagementInformation} [conceptManagement]
 * @return {BuildChanges}
 */
export const getBuildChanges = (project, serverStatus, conceptManagement) => {
  const sentimentStatus = getSentimentStatus(project);

  let upgradingScience = false;
  if (
    serverStatus.science_version !== project.last_build_info.science_version
  ) {
    upgradingScience = true;
  }

  const significantScienceChange = new Version(
    serverStatus.science_version
  ).isSignificantlyNewerThan(project.last_build_info.science_version);

  let addingSentiment = false;
  if (
    sentimentStatus === SENTIMENT_STATUS.BUILT_WITHOUT_SENTIMENT &&
    serverStatus.gpu
  ) {
    addingSentiment = true;
  }

  let hasUnbuiltAssertions = false;
  // This condition will have to change once we add more types of assertions
  if (conceptManagement?.hasIgnoreChanges) {
    hasUnbuiltAssertions = true;
  }

  let changingLanguage = false;
  if (project.next_build_language !== project.language) {
    changingLanguage = true;
  }

  const hasBuildChanges =
    upgradingScience ||
    addingSentiment ||
    hasUnbuiltAssertions ||
    changingLanguage;

  const canRebuild =
    hasBuildChanges && sentimentStatus !== SENTIMENT_STATUS.BUILDING;

  return {
    canRebuild,
    hasBuildChanges,
    upgradingScience,
    addingSentiment,
    hasUnbuiltAssertions,
    changingLanguage,
    significantScienceChange,
    currentLanguageName: getLanguageName(serverStatus, project.language),
    nextLanguageName: getLanguageName(serverStatus, project.next_build_language)
  };
};
