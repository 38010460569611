import React from 'react';
import { css } from '@emotion/core';

import { Button } from './core/Button';
import { Icon, IconTypes } from './icons';

export const CloseButton = props => {
  return (
    <Button
      aria-label="Close"
      flavor="subtle"
      css={css`
        /* Right align button */
        display: block;
        margin-left: auto;
      `}
      {...props}
    >
      <Icon type={IconTypes.CLOSE} size="20" />
    </Button>
  );
};
