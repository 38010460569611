import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';

export const LoginForm = forwardRef(
  (
    {
      onLogin,
      loading,
      username,
      onChangeUsername,
      password,
      onChangePassword
    },
    ref
  ) => {
    const usernameRef = useRef();
    const passwordRef = useRef();

    const focus = () => {
      if (username === '') {
        usernameRef.current.focus();
      } else {
        passwordRef.current.focus();
      }
    };

    useImperativeHandle(ref, () => ({ focus }));

    useEffect(() => {
      focus();
    }, []);

    return (
      <form
        className="fade-in"
        onSubmit={event => {
          event.preventDefault();
          onLogin();
        }}
      >
        <fieldset>
          <Input
            type="username"
            value={username}
            onChange={event => onChangeUsername(event.target.value)}
            ref={usernameRef}
          />
          <Input
            type="password"
            value={password}
            onChange={event => onChangePassword(event.target.value)}
            ref={passwordRef}
          />
        </fieldset>
        <LoginButton loading={loading} />
      </form>
    );
  }
);

const Input = forwardRef(({ type, ...props }, ref) => {
  return (
    <input
      aria-label={type}
      name={type}
      placeholder={type[0].toUpperCase() + type.slice(1)}
      className="input"
      type={type === 'password' ? type : 'text'}
      autoComplete={type}
      ref={ref}
      {...props}
    />
  );
});

const LoginButton = ({ loading }) => {
  return (
    <div className="btns-container">
      <button disabled={loading} type="submit" className="primary button">
        {loading && <span className="fade-in-loading-stripes" />}
        <span className="text">{loading ? 'Logging in...' : 'Log in'}</span>
      </button>
    </div>
  );
};
