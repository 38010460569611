export function commonSearchDefaults(params, { selection, projectHasLoaded }) {
  // If the project hasn't loaded yet, then selection and active concepts will
  // not have been loaded either. Return early to avoid setting values of any
  // properties that depend on them until they're ready.
  if (!projectHasLoaded) {
    return params;
  }

  const doc_match_type =
    !selection || !params.doc_match_type ? 'both' : params.doc_match_type;

  return { ...params, doc_match_type };
}
