import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function SortIcon({
  direction,
  iconColor,
  lineWidth,
  widthName,
  ...props
}) {
  return (
    <SVGWrapper {...props}>
      <SortIconIcon
        iconColor={iconColor}
        widthName={widthName}
        lineWidth={lineWidth}
        direction={direction}
      />
    </SVGWrapper>
  );
}

const SortIconIcon = ({ iconColor, widthName, lineWidth, direction }) => {
  switch (direction) {
    case 'up':
      return (
        <>
          <path
            d="M7.6773 13.5047C7.86363 13.6621 8.13637 13.6621 8.3227 13.5047L11.7912 10.574C12.1471 10.2733 11.9344 9.69208 11.4684 9.69208H4.53155C4.0656 9.69208 3.85293 10.2733 4.20885 10.574L7.6773 13.5047Z"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinejoin="round"
          />
          <SortIconUpPiece iconColor={iconColor} widthName={widthName} />
        </>
      );
    case 'down':
      return (
        <>
          <SortIconDownPiece iconColor={iconColor} widthName={widthName} />
          <path
            d="M7.35736 13.5605C7.72906 13.8723 8.2709 13.8723 8.6426 13.5605L12.054 10.6993C12.7694 10.0992 12.3451 8.93307 11.4114 8.93307H4.58856C3.65484 8.93307 3.23054 10.0992 3.94594 10.6993L7.35736 13.5605Z"
            fill={iconColor}
          />
        </>
      );
    default:
      return (
        <>
          <path
            d="M7.6773 2.76486C7.86363 2.60742 8.13637 2.60742 8.3227 2.76486L11.7912 5.69553C12.1471 5.99626 11.9344 6.57745 11.4684 6.57745H4.53155C4.0656 6.57745 3.85293 5.99626 4.20885 5.69553L7.6773 2.76486Z"
            stroke="#2C4249"
            strokeWidth={lineWidth}
            strokeLinejoin="round"
          />
          <path
            d="M7.6773 13.2338C7.86363 13.3912 8.13637 13.3912 8.3227 13.2338L11.7912 10.3031C12.1471 10.0024 11.9344 9.4212 11.4684 9.4212H4.53155C4.0656 9.4212 3.85293 10.0024 4.20885 10.3031L7.6773 13.2338Z"
            stroke="#2C4249"
            strokeWidth={lineWidth}
            strokeLinejoin="round"
          />
        </>
      );
  }
};

const SortIconUpPiece = ({ iconColor, widthName }) => {
  switch (widthName) {
    case 'light':
      return (
        <path
          d="M7.35736 2.76222C7.72906 2.45047 8.2709 2.45047 8.6426 2.76222L12.054 5.62346C12.7694 6.22349 12.3451 7.38965 11.4114 7.38965H4.58856C3.65484 7.38965 3.23054 6.22349 3.94594 5.62346L7.35736 2.76222Z"
          fill={iconColor}
        />
      );
    case 'bolder':
      return (
        <path
          d="M7.35736 2.76225C7.72906 2.4505 8.2709 2.4505 8.6426 2.76225L12.054 5.62349C12.7694 6.22352 12.3451 7.38968 11.4114 7.38968H4.58856C3.65484 7.38968 3.23054 6.22352 3.94594 5.62349L7.35736 2.76225Z"
          fill={iconColor}
        />
      );
    default:
      return (
        <path
          d="M7.35736 2.76219C7.72906 2.45043 8.2709 2.45043 8.6426 2.76219L12.054 5.62343C12.7694 6.22346 12.3451 7.38962 11.4114 7.38962H4.58856C3.65484 7.38962 3.23054 6.22346 3.94594 5.62343L7.35736 2.76219Z"
          fill={iconColor}
        />
      );
  }
};

const SortIconDownPiece = ({ iconColor, widthName }) => {
  switch (widthName) {
    case 'light':
      return (
        <path
          d="M7.6773 2.81805C7.86363 2.66061 8.13637 2.66061 8.3227 2.81805L11.7912 5.74873C12.1471 6.04946 11.9344 6.63064 11.4684 6.63064H4.53155C4.0656 6.63064 3.85293 6.04946 4.20885 5.74873L7.6773 2.81805Z"
          stroke={iconColor}
          strokeWidth="1.1"
          strokeLinejoin="round"
        />
      );
    case 'bolder':
      return (
        <path
          d="M7.6773 2.81808C7.86363 2.66064 8.13637 2.66064 8.3227 2.81808L11.7912 5.74876C12.1471 6.04949 11.9344 6.63068 11.4684 6.63068H4.53155C4.0656 6.63068 3.85293 6.04949 4.20885 5.74876L7.6773 2.81808Z"
          stroke={iconColor}
          strokeWidth="1.8"
          strokeLinejoin="round"
        />
      );
    default:
      return (
        <path
          d="M7.6773 2.81802C7.86363 2.66058 8.13637 2.66058 8.3227 2.81802L11.7912 5.7487C12.1471 6.04943 11.9344 6.63061 11.4684 6.63061H4.53155C4.0656 6.63061 3.85293 6.04943 4.20885 5.7487L7.6773 2.81802Z"
          stroke={iconColor}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      );
  }
};
