import { saveAs } from 'file-saver';

import assembleUrl from './assembleUrl';
import { redirectToLogin } from './common';
import request from './request';

const API_BASE = '/api/v5';

const contentType = {
  json: 'application/json',
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const wait = ms => new Promise(r => setTimeout(r, ms));
const RETRY_LIMIT = 5;

const handleResponseError = (response, retryFunc, numRetries = RETRY_LIMIT) => {
  if (!response) {
    throw {
      code: 'ERROR',
      message: 'An error occurred while attempting to access the Luminoso API'
    };
  }

  const { error: code, message, ...additionalInfo } = response;

  if (code === 'INVALID_TOKEN') {
    redirectToLogin(true);
  }

  if (code === 'TOO_MANY_REQUESTS' && numRetries > 0) {
    return wait(1000)
      .then(() => retryFunc())
      .then(response => response.json())
      .catch(response =>
        handleResponseError(response, retryFunc, numRetries - 1)
      );
  }

  throw { code, message, ...additionalInfo };
};

const makeRequest = (method, path, params, payload) => {
  const url = assembleUrl(API_BASE + path, params);

  const options = payload && {
    headers: { contentType: contentType.json },
    body: JSON.stringify(payload)
  };

  const requestFunc = payload
    ? () => request(method, url, options)
    : () => request(method, url);

  return requestFunc()
    .then(response => response.json())
    .catch(response => handleResponseError(response, requestFunc));
};

export const get = (path, params) => makeRequest('GET', path, params);

export const del = (path, payload, params) =>
  makeRequest('DELETE', path, params, payload);

export const put = (path, payload, params) =>
  makeRequest('PUT', path, params, payload);

export const post = (path, payload, params) =>
  makeRequest('POST', path, params, payload);

export const downloadSpreadsheet = (path, params, filename, usePost = true) => {
  const options = {
    headers: {
      accept: contentType[params.format],
      responseType: 'arraybuffer',
      ...(usePost && { contentType: contentType.json })
    },
    ...(usePost && { body: JSON.stringify(params) })
  };

  return (usePost
    ? request('POST', API_BASE + path, options)
    : request('GET', assembleUrl(API_BASE + path, params), options)
  )
    .then(response => response.blob())
    .then(blob => {
      saveAs(blob, filename, true);
      return 'ok';
    })
    .catch(handleResponseError);
};

const makeRequestWithFullUrl = (method, fullUrl, params, payload) => {
  const url = fullUrl;

  const options = payload && {
    headers: { contentType: contentType.json },
    body: JSON.stringify(payload)
  };

  const requestFunc = payload
    ? () => request(method, url, options)
    : () => request(method, url);

  return requestFunc()
    .then(response => response.json())
    .catch(response => handleResponseError(response, requestFunc));
};

export const getWithFullUrl = (fullUrl, params) =>
  makeRequestWithFullUrl('GET', fullUrl, params);

export const postWithFullUrl = (fullUrl, payload) =>
  makeRequestWithFullUrl('POST', fullUrl, null, payload);
