import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../../styles';
import { IconTypes } from './types';
import * as Icons from './icon_svgs';

const THEMES = {
  primary: Colors.blue5,
  'primary-active': Colors.blue7,
  placeholder: Colors.gray9,
  secondary: Colors.green7,
  tertiary: Colors.red5,
  light: '#fff',
  disabled: Colors.gray5
};

const THICKNESS = {
  light: 1.1,
  medium: 1.5,
  bolder: 1.8
};

export function Icon({ type, ...props }) {
  const iconColor = getIconColor(props.theme);
  const lineWidth = getLineWidth(props.thickness);
  const widthName = props.thickness ? props.thickness : 'medium';
  const IconSVG = ICONS[type];

  return (
    <IconSVG
      {...props}
      iconColor={iconColor}
      lineWidth={lineWidth}
      widthName={widthName}
    />
  );
}

Icon.propTypes = {
  /** The type of icon to be used. */
  type: PropTypes.string.isRequired,
  /** Alt text for the icon. Note that some iconTypes have this by default, and some don't. Please check the icon to be sure! */
  alt: PropTypes.string,
  /** The color theme to use. */
  theme: PropTypes.oneOf(Object.keys(THEMES)),
  /** The thickness of the line. */
  thickness: PropTypes.oneOf(['light', 'medium', 'bolder']),
  /** The direction arrow icons should point. */
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  /** X, Y, or center. */
  plane: PropTypes.oneOf(['x', 'y', 'center']),
  /** What emotion the sentiment is. */
  emotion: PropTypes.oneOf(['sad', 'neutral', 'happy']),
  /** The state (either 'checked' or 'unchecked') of the icon. Changes the appearance of the 'radio button' icon. */
  checkedState: PropTypes.oneOf(['checked', 'unchecked'])
};

const getIconColor = theme => THEMES[theme] ?? 'currentColor';

const getLineWidth = width => THICKNESS[width] ?? THICKNESS['medium'];

const ICONS = {
  [IconTypes.ADD]: Icons.Add,
  [IconTypes.APPROXIMATE]: Icons.Approximate,
  [IconTypes.APPROXIMATE_CIRCLE]: Icons.ApproximateCircle,
  [IconTypes.ARROW]: Icons.Arrow,
  [IconTypes.BRIEFCASE]: Icons.Briefcase,
  [IconTypes.CALENDAR]: Icons.Calender,
  [IconTypes.CARET_FILL]: Icons.CaretFill,
  [IconTypes.CARET_STROKE]: Icons.CaretStroke,
  [IconTypes.CHART]: Icons.Chart,
  [IconTypes.CHECK]: Icons.Check,
  [IconTypes.CIRCULAR_CHECK]: Icons.CircularCheck,
  [IconTypes.CIRCULAR_CLOSE]: Icons.CircularClose,
  [IconTypes.CIRCULAR_EXCLAMATION]: Icons.CircularExclamation,
  [IconTypes.CIRCULAR_INFO]: Icons.CircularInfo,
  [IconTypes.CLIPBOARD]: Icons.Clipboard,
  [IconTypes.CLOCK]: Icons.Clock,
  [IconTypes.CLOSE]: Icons.Close,
  [IconTypes.COORDINATE]: Icons.CoordinateIcon,
  [IconTypes.COPY]: Icons.Copy,
  [IconTypes.DOCUMENTS]: Icons.Documents,
  [IconTypes.DOUBLE_GEARS]: Icons.DoubleGears,
  [IconTypes.DOWNLOAD]: Icons.Download,
  [IconTypes.DRIVERS]: Icons.Drivers,
  [IconTypes.EQUALS]: Icons.Equals,
  [IconTypes.EXACT_CIRCLE]: Icons.ExactCircle,
  [IconTypes.EXCHANGE]: Icons.Exchange,
  [IconTypes.FACE]: Icons.Face,
  [IconTypes.FLASK]: Icons.Flask,
  [IconTypes.FLOPPY]: Icons.Floppy,
  [IconTypes.FUNNEL]: Icons.Funnel,
  [IconTypes.GALAXY]: Icons.Galaxy,
  [IconTypes.GEAR]: Icons.Gear,
  [IconTypes.HIGHLIGHTS]: Icons.Highlights,
  [IconTypes.INFO]: Icons.CircularInfo,
  [IconTypes.LAUNCHER]: Icons.Launcher,
  [IconTypes.LIST]: Icons.List,
  [IconTypes.MINUS]: Icons.Minus,
  [IconTypes.MORE]: Icons.More,
  [IconTypes.NOTES]: Icons.Notes,
  [IconTypes.PEN]: Icons.Pen,
  [IconTypes.PERSON]: Icons.Person,
  [IconTypes.RADIO_BUTTON]: Icons.RadioIcon,
  [IconTypes.ROTATE]: Icons.Rotate,
  [IconTypes.SEARCH]: Icons.Search,
  [IconTypes.SENTIMENT]: Icons.Sentiment,
  [IconTypes.SORT]: Icons.SortIcon,
  [IconTypes.SPINNER_DOTTED]: Icons.SpinnerDotted,
  [IconTypes.SPINNER_STROKE]: Icons.SpinnerStroke,
  [IconTypes.TABLE]: Icons.Table,
  [IconTypes.TRASH]: Icons.Trash,
  [IconTypes.UPLOAD]: Icons.Upload,
  [IconTypes.VOLUME]: Icons.Volume,
  [IconTypes.DASHBOARD]: Icons.Dashboard,
  [IconTypes.REVIEWS]: Icons.Reviews,
  [IconTypes.REDDIT]: Icons.Reddit,
  [IconTypes.SURVEYS]: Icons.Surveys,
  [IconTypes.AUDIBLE]: Icons.Audible,
  [IconTypes.AMAZON]: Icons.Amazon,
  [IconTypes.BESTBUY]: Icons.Bestbuy,
  [IconTypes.SAMSCLUB]: Icons.Samsclub,
  [IconTypes.TARGETCOM]: Icons.TargetCom,
  [IconTypes.WALMART]: Icons.Walmart
};
