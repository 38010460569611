import { css } from '@emotion/core';

/**
 * Luminoso Color Palette
 *
 * Any changes to these colors must also be made in:
 * - main/styles/core/variables.styl
 * - lui/less/main.less
 */
export const Colors = {
  gray0: '#f3f4f5',
  gray1: '#dde0e2',
  gray2: '#c7cdcf',
  gray3: '#b1b9bc',
  gray4: '#9ba5a9',
  gray5: '#859196',
  gray6: '#6e7e82',
  gray7: '#586a6f',
  gray8: '#42565c',
  gray9: '#2c4249',
  gray10: '#162f36',
  gray11: '#001b23',

  purple0: '#fcefff',
  purple1: '#e3c7f2',
  purple2: '#c99fe4',
  purple3: '#b077d7',
  purple4: '#964fc9',
  purple5: '#7d27bc',
  purple6: '#6e21aa',
  purple7: '#5e1a97',
  purple8: '#4f1385',
  purple9: '#400d72',
  purple10: '#300760',
  purple11: '#21004d',

  pink0: '#fff0f4',
  pink1: '#fbc3dd',
  pink2: '#f896c6',
  pink3: '#f468ae',
  pink4: '#f03b97',
  pink5: '#d53387',
  pink6: '#b92a76',
  pink7: '#9e2266',
  pink8: '#831956',
  pink9: '#681146',
  pink10: '#4c0835',
  pink11: '#310025',

  red0: '#fff1ed',
  red1: '#fac3c0',
  red2: '#f59694',
  red3: '#ef6867',
  red4: '#ea3a3a',
  red5: '#d03234',
  red6: '#b7292d',
  red7: '#9d2127',
  red8: '#831920',
  red9: '#69111a',
  red10: '#500813',
  red11: '#36000d',

  orange0: '#fff2d9',
  orange1: '#fed5a7',
  orange2: '#fcb875',
  orange3: '#fb9b43',
  orange4: '#e2893b',
  orange5: '#c87732',
  orange6: '#af652a',
  orange7: '#965322',
  orange8: '#7c4019',
  orange9: '#632e11',
  orange10: '#491c08',
  orange11: '#300a00',

  yellow0: '#fff883',
  yellow1: '#fce85a',
  yellow2: '#f8d830',
  yellow3: '#e0c22b',
  yellow4: '#c8ac25',
  yellow5: '#b19620',
  yellow6: '#99801b',
  yellow7: '#816b15',
  yellow8: '#695510',
  yellow9: '#523f0b',
  yellow10: '#3a2905',
  yellow11: '#221300',

  green0: '#ecffdb',
  green1: '#daffba',
  green2: '#c3ed96',
  green3: '#acdc72',
  green4: '#95ca4e',
  green5: '#85b444',
  green6: '#759e3b',
  green7: '#658831',
  green8: '#557227',
  green9: '#455c1d',
  green10: '#354613',
  green11: '#25300a',

  blue0: '#ebf5ff',
  blue1: '#b1e2f7',
  blue2: '#78d0ef',
  blue3: '#3ebde6',
  blue4: '#04aade',
  blue5: '#0396c2',
  blue6: '#0381a6',
  blue7: '#026d8a',
  blue8: '#02586e',
  blue9: '#014452',
  blue10: '#012f36',
  blue11: '#001b1a'
};

export const standardRadius = '0.1875rem';

export const Mixins = {
  shadowOutset: css`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  `,
  shadowOutsetHeavy: css`
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  `,
  shadowInset: css`
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  `,
  hintText: css`
    color: #888;
    font-style: italic;
  `,
  errorOutline: css`
    border: 1px solid ${Colors.red3};
    box-shadow: 0 0 5px ${Colors.red3};
  `,
  inputWithInsetIcon: css`
    position: relative;

    > input {
      padding: 0 2.5rem 0 0.5rem;
      margin: 0;
      box-sizing: border-box;
      font-size: 1rem;
    }

    > i,
    > button {
      line-height: 1;
      position: absolute;
      top: 0.5rem;
      right: 0.75rem;
      padding: 0;
      font-size: 1em;
      color: ${standardRadius};
      cursor: pointer;

      &:hover {
        color: #00c0f2;
      }

      &.disabled {
        cursor: auto;
        color: #bbb;
      }
    }
  `,
  roundedCorners: css`
    border-radius: ${standardRadius};
  `,
  fillViewport: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `,
  /**
   * Approximate the macOS + Chrome focus outline
   */
  focusOutline: css`
    outline: 4px solid Highlight;

    /* Let WebKit use native focus styles */
    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
    }
  `,
  ellipsify: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `
};

export const ZIndexes = {
  flyout: 1000,
  undoBanner: 1500,
  modalBackground: 2000,
  modal: 3000,
  tooltip: 4000
};

export const baseFontFamily = "'Lato', Helvetica, Arial, sans-serif";

export const center = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)'
};

/**
 * Add an opacity parameter to a color specified in hex format.
 *
 * Based on https://www.codegrepper.com/code-examples/javascript/javascript+add+alpha+to+hex
 *
 * @param {string} hexColor - The color without opacity, in hex format.
 * @param {number} opacity - The opacity to add to the color, as a
 *     real number between 0 and 1.
 * @returns {string} the color with the added opacity.
 */
export function addOpacityToColor(hexColor, opacity) {
  const hexOpacity = Math.round(opacity * 255).toString(16);
  return hexColor + hexOpacity;
}
