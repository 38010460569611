import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Add = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper alt="add" {...props}>
    <path
      d="M8.02636 3.01611V12.9839M3.01611 7.99997L12.9839 7.99997"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </SVGWrapper>
);
