import React from 'react';
import _ from 'lodash';

import { URLs } from '../constants';

export function redirectToLogin(appendDestParam = false) {
  const destParam = appendDestParam ? getDestParam() : '';
  window.location.href = `${URLs.ANALYTICS_LOGIN}${destParam}`;
}

function getDestParam() {
  return `?dest=${encodeURIComponent(window.location.href)}`;
}

/** does the 'permissions' field of the passed-in object contain `perm`? */
export function objHasPerm({ permissions }, perm) {
  return permissions.includes(perm);
}

export function camelCaseKeys(object) {
  return _.mapKeys(object, (val, key) => _.camelCase(key));
}

export function recursiveCamelCaseKeys(value) {
  if (_.isArray(value)) {
    return value.map(recursiveCamelCaseKeys);
  } else if (_.isObject(value)) {
    const camelCased = camelCaseKeys(value);
    return _.mapValues(camelCased, recursiveCamelCaseKeys);
  }
  return value;
}

/**
 * Template tagging function which only creates a string containing
 * interpolated values if none of those values are null/undefined.
 *
 * @param {string[]} strings Strings surrounding interpolated
 * values.
 * @param {...any} values Values interpolated into the template (things
 * surrouned by \$\{\}).
 *
 * @return {string|undefined} A normally interpolated string if no
 * values are null/undefined, but undefined otherwise.
 */
export function ifAllExist(strings, ...values) {
  if (values.some(value => value == null)) {
    return undefined;
  }

  return _.flatten(_.zip(strings, values)).join('');
}

/**
 * This function returns true if a string is parseable as a finite number
 * http://stackoverflow.com/questions/18082/validate-numbers-in-javascript-isnumeric
 */
export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * Count the number of non-Fragment children rendered by this component. Unlike
 * React.Children.count, this function does not count Fragment elements
 * themselves. Instead, it traverses any Fragments--including nested ones--and
 * counts their children as well.
 *
 * @param {*} children
 * @returns {number}
 */
export function countChildren(children) {
  return React.Children.toArray(children).reduce((sum, child) => {
    if (child.type === React.Fragment) {
      return sum + countChildren(child.props.children);
    } else {
      return sum + 1;
    }
  }, 0);
}
