import moment from 'moment';

import naturalSort from '../utils/NaturalSort';
import { thousandify } from '../utils/NumFmtUtils';

export class Constraint {
  constructor(name) {
    this.name = name;
  }

  isEmpty() {
    return true;
  }

  toString() {
    return `${this.name}: ${this.description}`;
  }

  get description() {
    throw new Error('This method should be implemented in subclasses');
  }
}

export class DiscreteConstraint extends Constraint {
  constructor(name, values = []) {
    super(name);
    this.values = values;
  }

  isEmpty() {
    return this.values.length === 0;
  }

  get description() {
    const sortedValues = [...this.values].sort(naturalSort);
    return `${sortedValues.join(', ')}`;
  }
}

export class DateRangeConstraint extends Constraint {
  constructor(name, minimum, maximum) {
    super(name);

    if (minimum !== undefined) {
      this.minimum =
        minimum instanceof moment
          ? minimum
          : moment.utc(minimum).startOf('day');
    }

    if (maximum !== undefined) {
      this.maximum =
        maximum instanceof moment ? maximum : moment.utc(maximum).endOf('day');
    }
  }

  isEmpty() {
    return this.minimum === undefined && this.maximum === undefined;
  }

  get description() {
    const formattedMinimum = this.minimum?.format('MMM D, YYYY');
    const formattedMaximum = this.maximum?.format('MMM D, YYYY');
    if (this.maximum === undefined) {
      return `${formattedMinimum} or later`;
    }
    if (this.minimum === undefined) {
      return `${formattedMaximum} or earlier`;
    }
    return `From ${formattedMinimum} to ${formattedMaximum}`;
  }
}

export class NumericRangeConstraint extends Constraint {
  constructor(name, minimum, maximum) {
    super(name);
    this.minimum = minimum;
    this.maximum = maximum;
  }

  isEmpty() {
    return this.minimum === undefined && this.maximum === undefined;
  }

  get description() {
    const formattedMinimum = thousandify(this.minimum);
    const formattedMaximum = thousandify(this.maximum);
    if (this.maximum === undefined) {
      return `${formattedMinimum} or more`;
    }
    if (this.minimum === undefined) {
      return `${formattedMaximum} or less`;
    }
    return `From ${formattedMinimum} to ${formattedMaximum}`;
  }
}
